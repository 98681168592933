.container-module .login-logout-page .login-logout-image {
display:none
}

.container-module .login-logout-page .login-logout-body {
box-sizing:border-box;width:100vw;height:100dvh;padding:24px 34px;background-color:var(--color-white)
}

.container-module .login-logout-page .login-logout-body .logo-wrapper {
box-sizing:border-box;margin-bottom:var(--spacing-lg)
}

.container-module .login-logout-page .login-logout-body .logo {
width:auto;max-height:40px
}

.container-module .login-logout-page .login-logout-body .branding-logo {
max-width:120px;max-height:60px
}

.container-module .login-logout-page .login-logout-body .logo-small {
width:auto;max-height:36px
}

.container-module .login-logout-page .login-logout-body .back-to-login-button {
width:126px
}

.container-module .login-logout-page .footer {
box-sizing:border-box;margin-top:20px
}

.container-module .login-logout-page .footer .footer-links {
box-sizing:border-box;margin:var(--spacing-lg) 0
}

.container-module .login-logout-page .footer .footer-links .footer-link {
display:flex;text-decoration:none
}

.container-module .login-logout-page .footer .footer-links .footer-link.link-with-margin-bottom {
margin-bottom:var(--spacing-lg)
}

.container-module .login-logout-page .footer .footer-links .footer-link ac-hyperlink ac-icon {
display:none
}

.container-module .login-logout-page .footer .footer-links .footer-link ac-hyperlink a {
text-decoration:none
}

.container-module .login-logout-page .footer .footer-links .footer-link ac-hyperlink a .ac-hyperlink-text {
text-decoration:none
}

.container-module .login-logout-page .footer .shiji-footer {
font-size:var(--font-size-sm)
}

.container-module .login-logout-page .footer .icon-hidden-in-mobile {
display:none
}

.container-module .header1 {
font-size:var(--font-size-h2);font-weight:var(--font-normal)
}

@media(min-width: 768px) {
.container-module .login-logout-page .login-logout-image {
display:block;width:100vw;height:100dvh;object-fit:cover;object-position:center
}

.container-module .login-logout-page .login-logout-body {
position:absolute;top:50%;left:50%;display:flex;justify-content:space-between;width:490px;height:auto;min-height:660px;max-height:100dvh;padding:40px 48px;overflow-y:auto;border-radius:var(--radius-box);box-shadow:0 1px 4px rgba(0,0,0,.5);transform:translate(-50%, -50%)
}

.container-module .login-logout-page .footer {
margin-top:60px;border-top:1px solid var(--color-gray200)
}

.container-module .login-logout-page .footer .footer-links {
display:flex
}

.container-module .login-logout-page .footer .footer-links .footer-link.link-with-margin-bottom {
margin-bottom:0
}

.container-module .login-logout-page .footer .icon-hidden-in-mobile {
display:inline-block;margin-top:var(--spacing-xs);margin-left:var(--spacing-xs);font-size:9px
}

.container-module .login-logout-page .header1 {
font-size:24px
}


}

@media(min-width: 1921px) {
.container-module .login-page .login-image {
background-image:url("./assets/city-v2.jpg")
}


}

